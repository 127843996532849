.banner-container {
  display: flex;
  justify-content: center;
}

.header-banner {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  margin-top: 1rem;
  margin-bottom: -1rem;
  border-radius: 10px;
}



.header-text {
  color: #ffffff;
  font-size: 1.8rem;

}

