@font-face {
    font-family: 'BAHNSCHRIFT';
    src: url('../fonts/BAHNSCHRIFT-6.TTF') format('truetype');
  }

.affix-header {
    background-color: #282a36;
    padding-top: 3rem;
    padding-bottom: 2rem;
    font-weight: bold;
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'BAHNSCHRIFT';
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
  }
  
  @media (max-width: 768px) {
    .affix-header {
      font-size: 1.5rem;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
    }
  }