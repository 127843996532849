.character-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
    background-color: #282a36;
    border-radius: 10px;
}

.character-search form {
    display: flex;
    flex-direction: row;
    justify-content: space-around; /* or try space-around */
    width: 100%;
    align-items: center;
}


.character-search select {
    flex-grow: 1;
    padding: 0.25rem 0.5rem;
    font-size: .8rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    margin-right: 1rem;
    outline: none;
    height: 2rem;
}

.character-search input {
    flex-grow: 1;
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    margin-right: 1rem;
    outline: none;
    height: 1.5rem;
}

.character-search select:focus,
.character-search input:focus {
    border-color: #0077ff;
    box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
}

.error-message {
    color: red;
    margin-top: 12px;
    
  }

.character-search button {
    cursor: pointer;
    background-color: #0100138a;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 0.25rem 0.5rem;
    margin-right: 1rem;
    transition: 0.1s;
    height: 2.0rem;
}

.character-search button:hover{
  background-color: #0077ff;
  transition: .5s;
  box-shadow: 0 0 0 1px white;
}

.character-search button:focus {
    box-shadow: 0 0 0 2px rgba(243, 244, 245, 0.2);
}