.keystone-calculator {
  font-family: 'Helvetica', 'Arial', sans-serif;
  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 1.5rem;
  background-color: #1c1c1c;
  color: white;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
}

h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.load-data-button {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.load-data-button:hover{
  transition: .1s;
  background-color: #007bff9d;
  box-shadow: 0 0 0 1px #007bff9d;

}

.dungeon-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  font-size: 14px;
}

.header-row,
.dungeon-row {
  display: contents;
}

.dungeon-name {
  grid-column: 1 / span 1;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}

.tyrannical-column,
.fortified-column {
  position: relative;
  grid-column: auto / span 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button {
  position: absolute;
  left: calc(100% - 25px); /* Adjusts the button position to the right */
  top: 8px;
  width: 24px;
  height: 20px;
  background-color: #007bff;
  color: white;
  font-size: 11px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}


.toggle-button:hover{
  transform: scale(1.1);
  background-color: #007bff9d;
  box-shadow: 0 0 0 1px white;;

}

.small-input {
  width: 50%;
  height: 26px;
  padding: 0 5px;
  margin-left: 5px;
  font-size: 14px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #222;
  color: white;
  text-align: center;
}

.sum-display {
  grid-column: 4 / span 1;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
}

.error-message {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ed5b45;
}