.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282a36;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  margin-top: 12px;
}

.keystone-calculator-container {
  background-color: #282a36;
}

.search-container{
  background-color: #282a36;
}

.instructions-container {
  position: absolute;
  left: -400px; /* Adjust this value to control the distance from the KeystoneCalculator component */
}

.calculator-content {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  position: relative;
}

.info-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 32px;
  left: 10px;
  z-index: 9999;
  transition: .3s;
}

.info-icon:hover{ 
  transform: scale(1.2);
}

.calculator-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.components-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align the top of both components */
  gap: 2rem; /* Adjust this value to control the space between the components */
  position: relative;
}